import React from 'react';
import { Flex, Image, useMediaQuery } from '@chakra-ui/core';

import { H2, H4 } from '@typography';
import ContentContainer from '../ContentContainer';
import { cloudfrontBaseUrl } from '../../lib/consts';
import { APP_CONTENT_WIDE_WIDTH, SMALL_SCREEN } from '../../styles/sizes';

import GradientBackground from '../../assets/JAFamiliesLoveBgDesktop.png';
import GradientBackgroundMobile from '../../assets/JAFamiliesLoveBgMobile.png';

const Reviews = () => {
  const [isMobile] = useMediaQuery(`(max-width: ${SMALL_SCREEN})`);

  return (
    <Flex
      style={{
        backgroundImage: isMobile
          ? `url(${GradientBackgroundMobile})`
          : `url(${GradientBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      className="people-love-zeta"
    >
      <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
        <Flex my={[10, 24]} direction="column">
          <Flex
            justify="center"
            align="center"
            direction="column"
            textAlign="center"
            mb={[10, 16]}
            px={[4, 0]}
          >
            <H2>People 💛 Zeta</H2>
            <H4 w={['auto', '800px']} center mt={4}>
              Zeta's aim is to help you win, no matter what stage you are at in your life.
              Here's what the experts have to say.
            </H4>
          </Flex>

          <Flex
            justify="center"
            alignSelf={['center', 'auto']}
            direction={['column', 'row']}
            mt={[0, 16]}
            mb={[0, 16]}
          >
            <Flex direction="column">
              <Image
                width="300px"
                src={`${cloudfrontBaseUrl}/personal-accounts/people-love-zeta/people_1_v3.png`}
              />
            </Flex>
            <Flex pt={['32px', 0]} pl={[0, '29px']} direction="column">
              <Image
                width="300px"
                src={`${cloudfrontBaseUrl}/personal-accounts/people-love-zeta/people_2.png`}
              />
            </Flex>
            <Flex pl={[0, '25px']} direction="column">
              <Image
                pt={['32px', 0]}
                width="300px"
                src={`${cloudfrontBaseUrl}/personal-accounts/people-love-zeta/people_3.png`}
              />
            </Flex>
          </Flex>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export default Reviews;
