import React from 'react';

import { colors } from '@theme';

export default function Wave1({ fill = colors.gray }) {
  return (
    <svg
      width="131"
      height="18"
      viewBox="0 0 131 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9.43125C30.1107 1.54875 85.3281 -4.69156 129 16"
        stroke="#EBEBEB"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="10 10"
      />
    </svg>
  );
}
