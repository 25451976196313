import React from 'react';
import { Flex, Text, Image, Heading } from '@chakra-ui/core';

import theme, { colors } from '@theme';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import Step1Icon from './assets/steps/Step1Icon.png';
import Step2Icon from './assets/steps/Step2Icon.png';
import Step3Icon from './assets/steps/Step3Icon.png';
import Step4Icon from './assets/steps/Step4Icon.png';
import Wave1 from './assets/Wave1';
import Wave2 from './assets/Wave2';

import { H2, H4 } from '@typography';
import { cloudfrontBaseUrl } from '../../lib/consts';

const HowItWorks = ({ variant }) => {
  const data = {};

  if (variant === 'joint-accounts') {
    data.steps = [
      'Tell us about you and your relationship',
      'Invite your partner so they can confirm their info.',
      'You each get your own Zeta Joint Card.',
      'Start spending and saving, as a team!',
    ];

    data.images = [
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step1.png`,
      Step2Icon,
      Step3Icon,
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step4.png`,
    ];
  } else if (variant === 'personal-accounts') {
    data.steps = [
      'Open your Zeta joint account',
      'Add on a personal account from your team drop down',
      'You can request a free personal debit card',
      'Start spending & saving privately',
    ];
  } else if (variant === 'solo-pass') {
    data.steps = [
      `Sign up using your Solo Pass code`,
      'Open your personal account in minutes',
      'You can request a free personal debit card',
      'Start spending & saving',
    ];
  }

  if (variant === 'solo-pass' || variant === 'personal-accounts') {
    data.images = [
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step1.png`,
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step2.png`,
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step3.png`,
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step4.png`,
    ];
  }
  const circle = num => {
    return (
      <Flex
        width="2.5rem"
        height="2.5rem"
        backgroundColor={colors.lightGray}
        borderRadius="50%"
        justify="center"
        align="center"
        mb={4}
      >
        <Text fontWeight={theme.fontWeights.bold}>{num}</Text>
      </Flex>
    );
  };
  return (
    <Flex py={[10, 24]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[10, 16]}
        px={[4, 0]}
      >
        <H2>How it works</H2>
        <H4 mt={4} center>
          Ready to get started? Here's what to expect.
        </H4>
      </Flex>

      <Flex direction="column" mt={4}>
        <Flex align="center" direction={['column', 'row']} px={[4, 0]}>
          <Flex
            direction={['row', 'column']}
            width={['100%', '25%']}
            px={[0, 4]}
            mb={[7, 0]}
          >
            <Flex
              align="center"
              justify={['flex-end', 'space-between']}
              pr={[8, 0]}
              width={['50%', 'auto']}
            >
              <Flex>
                <Image h="95px" w="82px" src={data.images[0]} />
              </Flex>
              <DesktopOnly>
                <Wave1 />
              </DesktopOnly>
            </Flex>
            <Flex pr={[4, 0]} mt={[2, '19px']} direction="column" width={['50%', 'auto']}>
              {circle(1)}
              <Text
                h={variant === 'personal-accounts' && '48px'}
                w={variant === 'solo-pass' && '150px'}
                fontWeight={theme.fontWeights.semibold}
              >
                {data.steps[0]}
              </Text>
            </Flex>
          </Flex>
          <Flex
            direction={['row', 'column']}
            width={['100%', '25%']}
            px={[0, 4]}
            mb={[7, 0]}
          >
            <Flex
              align="center"
              justify={['flex-end', 'space-between']}
              pr={[8, 0]}
              width={['50%', 'auto']}
            >
              <Image
                h="79px"
                w={
                  variant === 'personal-accounts' || variant === 'solo-pass'
                    ? '72px'
                    : '120px'
                }
                src={data.images[1]}
              />
              <DesktopOnly>
                <Flex pt={6}>
                  <Wave2 />
                </Flex>
              </DesktopOnly>
            </Flex>
            <Flex pr={[4, 0]} mt={[2, 8]} direction="column" width={['50%', 'auto']}>
              {circle(2)}
              <Text fontWeight={theme.fontWeights.semibold}>{data.steps[1]}</Text>
            </Flex>
          </Flex>
          <Flex
            direction={['row', 'column']}
            width={['100%', '25%']}
            px={[0, 4]}
            mb={[7, 0]}
          >
            <Flex
              align="center"
              justify={['flex-end', 'space-between']}
              pr={[8, 0]}
              width={['50%', 'auto']}
            >
              <Image h="82px" w="117px" src={data.images[2]} />
              <DesktopOnly>
                <Wave1 />
              </DesktopOnly>
            </Flex>
            <Flex pr={[4, 0]} mt={[2, 8]} direction="column" width={['50%', 'auto']}>
              {circle(3)}
              <Text fontWeight={theme.fontWeights.semibold}>{data.steps[2]}</Text>
            </Flex>
          </Flex>
          <Flex
            direction={['row', 'column']}
            width={['100%', '25%']}
            px={[0, 4]}
            mb={[7, 0]}
          >
            <Flex
              align="center"
              justify={['flex-end', 'space-between']}
              pr={[8, 0]}
              width={['50%', 'auto']}
            >
              <Image h="83px" src={data.images[3]} />
            </Flex>
            <Flex pr={[4, 0]} mt={[2, 8]} direction="column" width={['50%', 'auto']}>
              {circle(4)}
              <Text h="48px" fontWeight={theme.fontWeights.semibold}>
                {data.steps[3]}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HowItWorks;
