import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/core';

import { colors } from '@theme';
import {
  Metadata,
  KeyFeatures,
  Anchor,
  HelpfulResources,
  Reviews,
} from '@personalaccounts';
import { H1, H4, Text } from '@typography';

import Layout from '../components/layout';
import ContentContainer from '../components/ContentContainer';
import { cloudfrontBaseUrl, PAFAQContent } from '../lib/consts';
import { DesktopOnly, MobileOnly } from '../layouts/Footer';
import { APP_CONTENT_DESKTOP_WIDTH, APP_CONTENT_WIDE_WIDTH } from '../styles/sizes';
import VIP from '../components/jointAccount/VIP';
import CardFeatures from '../components/jointAccount/cardFeatures';
import HowItWorks from '../components/jointAccount/howItWorks';
import FAQs from '../components/jointAccount/FAQs';

const PersonalBankAccount = () => {
  return (
    <Layout noRootContainer customBackgroundColor={colors.toastedAlmond}>
      <Metadata />

      <Flex bg={colors.toastedAlmond} pt={[26, 18]}>
        <ContentContainer>
          <Flex align="center" direction="column" w="100%" pt={[0, 18]}>
            <Box w={['320px', 'auto']}>
              <H1>Zeta Personal Accounts</H1>
            </Box>
            <H4 as="h2" mt={8} center>
              Add on a personal account designed
              <br />
              to help you bank the way you want.
            </H4>
          </Flex>

          <DesktopOnly>
            <Image
              mt="72px"
              src={`${cloudfrontBaseUrl}/personal-accounts/desktop-hero.png`}
            />
          </DesktopOnly>

          <MobileOnly>
            <Image
              mt="52px"
              src={`${cloudfrontBaseUrl}/personal-accounts/mobile-hero.png`}
            />

            <Flex align="center" my="58px" justify="center">
              <Text
                ml={4}
                fontStyle="italic"
                width="200px"
                size="10px"
                style={{ textAlign: 'center' }}
              >
                Zeta Joint Account is a deposit account powered by Piermont Bank, Member
                FDIC.
              </Text>
            </Flex>
          </MobileOnly>
        </ContentContainer>
      </Flex>

      <Flex mb={['50px', '82px']}>
        <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
          <KeyFeatures />
        </ContentContainer>
      </Flex>

      <VIP />

      <Flex bg={colors.iceberg} className="theres-even-more-to-love">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <CardFeatures />
        </ContentContainer>
      </Flex>

      <Reviews />

      <Flex justify="center" className="how-it-works">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <HowItWorks variant="personal-accounts" />
        </ContentContainer>
      </Flex>

      <Flex bg={colors.background} className="FAQs">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <FAQs content={PAFAQContent} />
        </ContentContainer>
      </Flex>

      <HelpfulResources />

      <Anchor />
    </Layout>
  );
};

export default PersonalBankAccount;
