import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/core';

import { colors } from '@theme';
import { H2, H3, Text } from '@typography';
import { Tag } from '@zeta';
import { personalAccountKeyFeatures } from '../../lib/consts';

const Feature = ({ data: { tag, header, body, image }, index, soloPass = false }) => {
  const soloPassBody =
    "The only way you can sign up for a personal account without a Joint Account! Elevate your personal banking experience with Zeta's complete control over your finances with user-friendly features to manage money your way.";

  if (index % 2 === 0) {
    return (
      <Flex
        backgroundColor={soloPass ? colors.primary : [colors.backgroundDos, colors.white]}
        borderRadius={['15px', 0]}
        mt={index !== 0 && [8, 0]}
        mx={[4, 0]}
        px={[4, 0]}
        pb={['32px', 0]}
        direction="row"
        justify={['center', 'space-between']}
        wrap={['wrap-reverse', 'no-wrap']}
      >
        <Flex
          width={['100%', '45%']}
          direction="column"
          justify={['left', 'center']}
          textAlign={['left', 'left']}
        >
          <Tag
            borderColor={soloPass ? colors.yellowLight : colors.black}
            textColor={soloPass ? colors.yellowLight : colors.black}
            width={tag.width}
            text={tag.title}
          />
          <Box width={soloPass && index === 0 ? '350px' : header.width} pt="22px">
            <H3
              style={{
                color: soloPass ? colors.yellowLight : colors.black,
              }}
              noAlign
            >
              {soloPass && index === 0 ? 'Free personal account' : header.title}
            </H3>
          </Box>
          {body === 'DISCLAIMER' ? (
            <Text
              width={['312px', '365px']}
              lineHeight={['24px', '25px']}
              color={soloPass ? colors.white : [colors.black, colors.boulder]}
              pt="24px"
            >
              The funds in your Zeta account are FDIC insured{' '}
              <a href="#zeta-disclosure">
                <sup style={{ color: colors.greenPea }}>1</sup>
              </a>{' '}
              through Piermont Bank, Member FDIC, up to the standard maximum of $250,000
              per depositor, per institution. Plus, two-factor authentication & data
              encryption keep your data secure. We don't store your external login
              credentials and will never sell your data.
            </Text>
          ) : (
            <Text
              width={['312px', '365px']}
              lineHeight={['24px', '25px']}
              color={soloPass ? colors.white : [colors.black, colors.boulder]}
              pt="24px"
            >
              {soloPass && index === 0 ? soloPassBody : body}
            </Text>
          )}
        </Flex>
        <Flex
          pt={['42px', '']}
          mb={['56px', '']}
          width={['auto', '50%']}
          justify={['center', 'flex-end']}
        >
          <Image
            width={['100%', image.desktop.width]}
            height={['100%', image.desktop.height]}
            src={image.url}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      backgroundColor={soloPass ? colors.primary : [colors.backgroundDos, colors.white]}
      borderRadius={['15px', 0]}
      pb={['32px', 0]}
      direction="row"
      justify={['center', 'space-between']}
      wrap={['wrap', 'no-wrap']}
      mt={[8, 0]}
      mx={[4, 0]}
      px={[4, 0]}
    >
      {index !== 5 ? (
        <Flex
          pt={['42px', '']}
          mb={['56px', '']}
          width={['100%', '50%']}
          justify={['center', 'flex-start']}
        >
          <Image
            width={['100%', image.desktop.width]}
            height={['100%', image.desktop.height]}
            src={image.url}
          />
        </Flex>
      ) : (
        <Flex
          pt={['42px', '']}
          pb={['56px', '']}
          width={['100%', '45%']}
          justify={['center', 'flex-start']}
        >
          <Image width={['100%', '90%']} src={image.url} maxHeight={'100%'} />
        </Flex>
      )}

      <Flex
        width={['100%', '45%']}
        direction="column"
        justify={['left', 'center']}
        textAlign={['left', 'left']}
      >
        <Tag
          borderColor={soloPass ? colors.yellowLight : colors.black}
          textColor={soloPass ? colors.yellowLight : colors.black}
          width={tag.width}
          text={tag.title}
        />
        <Box width={header.width} pt="22px">
          <H3
            style={{
              color: soloPass ? colors.yellowLight : colors.black,
            }}
            noAlign
          >
            {header.title}
          </H3>
        </Box>
        {body === 'DISCLAIMER' ? (
          <Text
            width={['312px', '365px']}
            lineHeight={['24px', '25px']}
            color={soloPass ? colors.white : [colors.black, colors.boulder]}
            pt="24px"
          >
            The funds in your Zeta account are FDIC insured{' '}
            <a href="#zeta-disclosure">
              <sup style={{ color: colors.greenPea }}>1*</sup>
            </a>{' '}
            through Piermont Bank, Member FDIC, up to the standard maximum of $250,000 per
            depositor, per institution. Plus, two-factor authentication & data encryption
            keep your data secure. We don't store your external login credentials and will
            never sell your data.
          </Text>
        ) : (
          <Text
            width={['312px', '365px']}
            lineHeight={['24px', '25px']}
            color={soloPass ? colors.white : [colors.black, colors.boulder]}
            pt="24px"
          >
            {body}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

const Spacer = () => {
  return <Box mt={['0px', '18px']} />;
};

const KeyFeatures = ({ soloPass = false }) => {
  return (
    <Flex py={[10, 24]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[0, 16]}
        px={[4, 0]}
      >
        <H2
          style={{
            color: soloPass ? colors.white : colors.black,
          }}
        >
          Our key features
        </H2>

        <Flex
          w={['252px', '544px']}
          mt="12px"
          mb={8}
          justify="center"
          style={{
            color: soloPass ? colors.white : colors.black,
          }}
        >
          {soloPass
            ? 'For those who prefer to have more autonomy with personal accounts:'
            : 'For those families who prefer to have both joint and personal accounts:'}
        </Flex>
      </Flex>

      {personalAccountKeyFeatures.map((feature, idx) => (
        <>
          <Spacer />
          <Spacer />
          <Feature soloPass={soloPass} data={feature} index={idx} />
          {idx < 5 && (
            <>
              <Spacer />
              <Spacer />
            </>
          )}
        </>
      ))}
    </Flex>
  );
};

export default KeyFeatures;
