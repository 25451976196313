import React from 'react';
import { Flex, Image, Text, Box } from '@chakra-ui/core';

import ContentContainer from '../ContentContainer';
import { APP_CONTENT_DESKTOP_WIDTH } from '../../styles/sizes';
import { cloudfrontBaseUrl, CURRENT_VIP_APY_RATE } from '../../lib/consts';
import { colors } from '@theme';
import { H3 } from '@typography';

const VIP = () => {
  const goToNationalDepositRate = () => {
    window.open(
      'https://www.fdic.gov/resources/bankers/national-rates/index.html',
      '_blank',
    );
  };

  const goToVIP = () => {
    window.open('https://www.askzeta.com/help/zeta-vip/what-is-zetas-vip-tier', '_blank');
  };

  return (
    <Flex bg={colors.yellowLight} className="level-up-with-vip">
      <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
        <Flex height={['740px', '600px']} width="100%" direction={['column', 'row']}>
          <Flex
            pt={[8, 0]}
            mt={[8, 0]}
            justify="center"
            align={['center', 'start']}
            direction="column"
          >
            <Image
              width={['75%', '70%']}
              src={`${cloudfrontBaseUrl}/images/zeta_vip_interest_comp.png`}
            />
          </Flex>

          <Flex
            px={[4, 2]}
            mt={[0, '52px']}
            width={['100%', '75%']}
            justify="center"
            align="center"
            direction="column"
          >
            <Box pt={['50px', 0]} width="100%">
              <H3>
                Level up to {CURRENT_VIP_APY_RATE} APY
                <a href="#zeta-disclosure">
                  <sup style={{ color: colors.greenPea }}>2</sup>
                </a>{' '}
                with VIP
              </H3>
            </Box>
            <Text textAlign={['center', 'left']} pt="24px" fontSize={['16px', '18px']}>
              Want VIP status? Set up direct deposit* or maintain a $5,000 minimum daily
              average balance* to earn interest on your entire Zeta account balance (with
              no limits). Plus, as a VIP you'll be eligible for other perks such as free
              check writing, higher account limits and access to features (like mobile
              check deposit).
            </Text>

            <Text
              textAlign={['center', 'left']}
              fontStyle="italic"
              pt="16px"
              fontSize={['12px']}
            >
              *Disclosure: Zeta Accounts who have a qualifying direct deposit totaling
              $1,000 or maintain a $5,000 minimum daily average balance within a calendar
              month will be upgraded to our VIP tier. National deposit rate found{' '}
              <span
                onClick={goToNationalDepositRate}
                style={{
                  color: colors.greenPea,
                  fontSize: '12px',
                  cursor: 'pointer',
                }}
              >
                here
              </span>
              . Additional information about our VIP tier can be found{' '}
              <span
                onClick={goToVIP}
                style={{
                  color: colors.greenPea,
                  fontSize: '12px',
                  cursor: 'pointer',
                }}
              >
                here
              </span>
              .
            </Text>
          </Flex>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export default VIP;
