import React from 'react';
import './base.css';
import Navigation from '../Navigation/Navigation';
import Footer from '../layouts/Footer';
import { ChakraProvider, Box } from '@chakra-ui/core';
import theme, { colors } from '@theme';
import ContentContainer from './ContentContainer';

const Template = props => {
  const {
    location,
    children,
    isMobile = false,
    noRootContainer = false,
    showGetStartedMobile,
    showGetStartedDesktop,
    newsletterOnly = false,
    mode = 'light',
    newNav = false,
    noNav = false,
    legal,
    transparent = false,
    customBackgroundColor,
  } = props;

  let rootPath = `/`;
  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`;
  }

  return (
    <ChakraProvider resetCSS theme={theme}>
      <Box>
        {!isMobile && !noNav && (
          <Navigation
            transparent={transparent}
            legal={legal}
            showGetStartedMobile={showGetStartedMobile}
            showGetStartedDesktop={showGetStartedDesktop}
            mode={mode}
            customBackgroundColor={customBackgroundColor}
          />
        )}
      </Box>
      {noRootContainer ? children : <ContentContainer>{children}</ContentContainer>}
      {!isMobile && <Footer />}
    </ChakraProvider>
  );
};

export default Template;
