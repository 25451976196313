import React from 'react';

import { colors } from '@theme';

export default function Wave2({ fill = colors.gray }) {
  return (
    <svg
      width="131"
      height="26"
      viewBox="0 0 131 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M128.5 12C100.5 24 45.5 33.5 2 2"
        stroke="#EBEBEB"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="10 10"
      />
    </svg>
  );
}
