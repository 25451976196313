import React, { useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import {
  Flex,
  Text,
  Box,
  Image,
  Drawer,
  DrawerBody,
  DrawerContent,
} from '@chakra-ui/core';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { useLocation } from '@reach/router';

import { colors } from '@theme';
import ContentContainer from '../components/ContentContainer';
import { NavButton, Button } from '@zeta';
import { SMALL_SCREEN } from '../styles/sizes';
import Link from './Link';

// When adding, modifying or removing a nav item make sure you update the Hamburger
// and LargeNav components within this file to cover both desktop and mobile states.
// Anything you modify in this file needs to be updated on the zeta-blog side as well.

const Navigation = ({
  showGetStartedMobile = true,
  showGetStartedDesktop = true,
  mode = 'light',
  legal,
  transparent = false,
  customBackgroundColor = null,
}) => {
  // state for is it transparent right now.
  const [isNavTransparent, setIsNavTransparent] = useState(transparent);

  const lightMode = mode === 'light';

  const homeNav = mode === 'home';

  const NavLink = props => {
    const { text, location, mobile = false, active, ...rest } = props;
    return (
      <Link className="nav-link" noNewTab href={location} {...rest}>
        <Text
          as="span"
          fontWeight="semibold"
          color={active ? colors.greenPea : colors.primary}
          fontSize="16px"
        >
          {text}
        </Text>
      </Link>
    );
  };

  const LogoLink = ({ mobile = false }) => {
    return (
      <Link className="nav-link" noNewTab href="/">
        <Flex ml={legal ? [0, -1] : [0, -8]} direction="column" justify="center">
          <Image
            loading="eager"
            alt=""
            src={
              homeNav
                ? 'https://d142whjg6dsdg2.cloudfront.net/logos/zeta-logo-lightmode.svg'
                : lightMode
                ? 'https://d142whjg6dsdg2.cloudfront.net/logos/zeta-logo-lightmode.svg'
                : 'https://d142whjg6dsdg2.cloudfront.net/logos/zeta-logo-darkmode.svg'
            }
          />
        </Flex>
      </Link>
    );
  };

  const Hamburger = ({ toggleOpen }) => {
    const location = useLocation();
    const pathname = location.pathname;
    return (
      <Flex zIndex={100} direction="column" height="100%" mt={'1.5rem'}>
        <Flex px="24px" justify="space-between" direction="row">
          <Link className="nav-link" noNewTab href="/" onClick={toggleOpen} timeout={500}>
            <Flex ml={legal ? [0, -1] : [0, -8]} direction="column" justify="center">
              <Image
                loading="eager"
                alt=""
                src={
                  homeNav
                    ? 'https://d142whjg6dsdg2.cloudfront.net/logos/zeta-logo-lightmode.svg'
                    : lightMode
                    ? 'https://d142whjg6dsdg2.cloudfront.net/logos/zeta-logo-lightmode.svg'
                    : 'https://d142whjg6dsdg2.cloudfront.net/logos/zeta-logo-darkmode.svg'
                }
              />
            </Flex>
          </Link>
          <CloseIcon onClick={toggleOpen} />
        </Flex>
        <Box pl="12px" mt="32px">
          <Text fontSize="16px" color={colors.greenPea}>
            PRODUCTS
          </Text>
          <Box pl="12px">
            <Link
              timeout={500}
              onClick={toggleOpen}
              style={{
                color: pathname.includes('/joint-bank-account')
                  ? colors.greenPea
                  : colors.black,
              }}
              noNewTab
              href={'/joint-bank-account'}
            >
              <Text fontSize="24px">Joint Accounts</Text>
            </Link>
            <Link
              timeout={500}
              onClick={toggleOpen}
              style={{
                color: pathname.includes('/personal-bank-account')
                  ? colors.greenPea
                  : colors.black,
              }}
              noNewTab
              href={'/personal-bank-account'}
            >
              <Text fontSize="24px">Personal Accounts</Text>
            </Link>
            <Link
              timeout={500}
              onClick={toggleOpen}
              style={{
                color: pathname.includes('/zeta-plus') ? colors.greenPea : colors.black,
              }}
              noNewTab
              href={'/zeta-plus'}
            >
              <Text fontSize="24px">Zeta+</Text>
            </Link>
            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/help') ? colors.greenPea : colors.black,
              }}
              noNewTab
              href="/help"
            >
              <Text fontSize="24px">FAQs</Text>
            </Link>
            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/security') ? colors.greenPea : colors.black,
              }}
              noNewTab
              href="/security"
            >
              <Text fontSize="24px">Security</Text>
            </Link>
          </Box>

          <Text mt="24px" fontSize="16px" color={colors.greenPea}>
            TOOLS
          </Text>
          <Box pl="12px">
            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/magazine') ? colors.greenPea : colors.black,
              }}
              noNewTab
              href="/magazine"
            >
              <Text fontSize="24px">Magazine</Text>
            </Link>
            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/magazine/#guides-quizzes-tools-podcast')
                  ? colors.greenPea
                  : colors.black,
              }}
              noNewTab
              href="/magazine/#guides-quizzes-tools-podcast"
            >
              <Text fontSize="24px">Guides</Text>
            </Link>
            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/sentwithlove')
                  ? colors.greenPea
                  : colors.black,
              }}
              noNewTab
              href="/sentwithlove"
            >
              <Text fontSize="24px">#sentwithlove</Text>
            </Link>

            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/swag') ? colors.greenPea : colors.black,
              }}
              noNewTab
              href="/swag"
            >
              <Text fontSize="24px">Swag</Text>
            </Link>
          </Box>

          <Text mt="24px" fontSize="16px" color={colors.greenPea}>
            GET HELP
          </Text>
          <Box pl="12px">
            <Link
              onClick={toggleOpen}
              timeout={500}
              noNewTab
              href="mailto:support@askzeta.com"
            >
              <Text fontSize="24px">support@askzeta.com</Text>
            </Link>

            <Link
              onClick={toggleOpen}
              timeout={500}
              href="https://card.askzeta.com/messages"
            >
              <Text fontSize="24px">Secure chat</Text>
            </Link>
          </Box>

          <Text mt="24px" fontSize="16px" color={colors.greenPea}>
            WHO WE ARE
          </Text>
          <Box pl="12px">
            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/aboutus') ? colors.greenPea : colors.black,
              }}
              noNewTab
              href="/aboutus"
            >
              <Text fontSize="24px">About us</Text>
            </Link>
            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/careers') ? colors.greenPea : colors.black,
              }}
              noNewTab
              href="/careers"
            >
              <Text fontSize="24px">Careers</Text>
            </Link>
            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/legal') ? colors.greenPea : colors.black,
              }}
              noNewTab
              href="/legal"
            >
              <Text fontSize="24px">Legal</Text>
            </Link>

            <Link
              onClick={toggleOpen}
              timeout={500}
              style={{
                color: pathname.includes('/press') ? colors.greenPea : colors.black,
              }}
              noNewTab
              href="/press"
            >
              <Text fontSize="24px">Press</Text>
            </Link>
          </Box>
        </Box>
        <Flex
          style={{}}
          my={8}
          py={6}
          width="100%"
          backgroundColor={colors.greenLight}
          justify="center"
          align="center"
        >
          <Button
            text="Get started"
            variant="greenPea"
            onClick={() => {
              window.location.href = 'https://askzeta.app.link/getstarted';
            }}
          />
        </Flex>
      </Flex>
    );
  };

  const NavContainer = ({ children, mobile = false }) => {
    let bgColor =
      transparent && isNavTransparent
        ? 'transparent'
        : homeNav
        ? colors.white
        : lightMode
        ? colors.iceberg
        : colors.primary;

    if (customBackgroundColor !== null) {
      bgColor = customBackgroundColor;
    }

    return (
      <Box
        py={['1rem', '1rem']}
        as="nav"
        position="fixed"
        backgroundColor={bgColor}
        style={{
          top: 0,
          width: '100%',
          zIndex: 100,
        }}
      >
        {children}
      </Box>
    );
  };

  const GetStartedBtn = ({ isMobile = false }) => {
    const getStarted = () => {
      window.location.href = 'https://askzeta.app.link/getstarted';
    };
    return (
      <Flex style={{ marginLeft: isMobile ? undefined : '2rem' }}>
        <NavButton
          transparent
          variant="primary"
          onClick={getStarted}
          text="Get started"
        />
      </Flex>
    );
  };

  const LargeNav = () => {
    const location = useLocation();
    const pathname = location.pathname;

    return (
      <NavContainer>
        <ContentContainer>
          <Flex direction="row" justify="space-between">
            <Flex justify="flex-start" align="center">
              <LogoLink />
              <NavLink
                className="nav-link"
                active={pathname.includes('/joint-bank-account')}
                style={{
                  marginLeft: '4rem',
                }}
                text={'Joint Accounts'}
                location={'/joint-bank-account'}
              />
              <NavLink
                className="nav-link"
                active={pathname.includes('/personal-bank-account')}
                style={{
                  marginLeft: '4rem',
                }}
                text={'Personal Accounts'}
                location={'/personal-bank-account'}
              />
              <NavLink
                className="nav-link"
                active={pathname.includes('/zeta-plus')}
                style={{
                  marginLeft: '4rem',
                }}
                text={'Zeta+'}
                location={'/zeta-plus'}
              />
              <NavLink
                className="nav-link"
                style={{ marginLeft: '4rem' }}
                text={'Magazine'}
                location={'/magazine'}
              />
            </Flex>
            {showGetStartedDesktop && (
              <Flex>
                <Flex align="center" justify="flex-end">
                  <GetStartedBtn />
                </Flex>
              </Flex>
            )}
          </Flex>
        </ContentContainer>
      </NavContainer>
    );
  };

  const SmallNav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    const styles = {
      burger: {
        width: '2rem',
        height: '.25rem',
        borderRadius: '10px',
        backgroundColor: colors.black,
        transformOrigin: '1px',
        transition: `all 0.3s linear`,
      },
    };

    return (
      <>
        {!isOpen && (
          <NavContainer mobile>
            <Flex pl="24px" pr="24px" justify="space-between" align="center">
              <LogoLink mobile />
              <Flex align="center">
                <Flex mr={6}>
                  <GetStartedBtn />
                </Flex>
                <HamburgerIcon boxSize="2rem" onClick={toggleOpen} />
              </Flex>
            </Flex>
          </NavContainer>
        )}

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={toggleOpen}
          size="full"
          isFullHeight
        >
          {/* <DrawerOverlay /> */}
          <DrawerContent maxHeight="100vh" overflowY="auto">
            <DrawerBody style={{ padding: 0 }}>
              <Hamburger toggleOpen={toggleOpen} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  };

  const DesktopOnly = styled.div`
    @media (max-width: ${SMALL_SCREEN}) {
      display: none;
    }
  `;
  const MobileOnly = styled.div`
    @media (min-width: ${SMALL_SCREEN}) {
      display: none;
    }
  `;
  return (
    <>
      <DesktopOnly>
        <LargeNav />
      </DesktopOnly>
      <MobileOnly>
        <SmallNav />
      </MobileOnly>
      {transparent && (
        <Waypoint
          onEnter={() => {
            if (customBackgroundColor === null) {
              setIsNavTransparent(true);
            }
          }}
          onLeave={() => {
            if (customBackgroundColor === null) {
              setIsNavTransparent(false);
            }
          }}
        />
      )}
    </>
  );
};

export default Navigation;
