import React from 'react';
import { Flex, Image, Box, Text as ChakraText } from '@chakra-ui/core';

import { H3, Text } from '@typography';
import { TextLink } from '@zeta';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';

const ContentResource = ({
  variant,
  imageSource,
  title,
  description,
  ctaUrl,
  customTitleSize,
  titleBreak = false,
  center = false,
}) => {
  const ultraMega = variant === 'ultraMega';

  return (
    <Flex
      mx={['12px', 0]}
      height="100%"
      direction={['column', ultraMega ? 'row' : 'column']}
      px={[4, 0]}
      mb={[18, 0]}
    >
      <Image
        loading="eager"
        // height={['272px', ultraMega ? '320px' : '200px']}
        width={['100%', ultraMega ? '580px' : '300px']}
        // border="1px"
        // borderRadius={['24px', '15px']}
        src={imageSource}
      />

      <Flex
        justify={center ? 'center' : 'auto'}
        pl={[0, ultraMega ? '48px' : 0]}
        pt={[0, !ultraMega ? '24px' : 0]}
        direction="column"
      >
        <DesktopOnly>
          <H3
            titleBreak
            customSize={customTitleSize}
            noAlign
            // forgive me
            style={{
              width: !ultraMega ? (titleBreak ? '220px' : '300px') : '100%',
            }}
          >
            {title}
          </H3>
        </DesktopOnly>

        <MobileOnly>
          <ChakraText pt="24px" fontWeight={600} fontSize="18px">
            {title}
          </ChakraText>
        </MobileOnly>

        <Text style={{ marginTop: '16px', width: !ultraMega ? '300px' : '100%' }}>
          {description}
        </Text>

        <Box mt="16px" height="20px" width="120px">
          <TextLink text="Read more" noNewTab to={ctaUrl} variant="primary" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default ContentResource;
